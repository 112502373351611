<template>
    <div
        class="flex h-full w-full rounded-xl bg-white text-grind drop-shadow-[0_0_8px_rgba(20,20,20,0.1)]"
        :class="mainClasses"
    >
        <div class="bg-gray-200" :class="imageContainerClasses">
            <StoryblokImage
                v-if="card.image"
                :src="card.image.src"
                :alt="card.image.alt"
                class="h-full w-full object-cover"
                :class="imageClasses"
                itemprop="image"
                sizes="200px md:210px lg:270px xl:350px"
            />
        </div>
        <div
            class="flex flex-1 flex-col justify-between"
            :class="contentClasses"
        >
            <div class="flex flex-col">
                <TagGroup
                    v-if="tags.length"
                    :tags="tags"
                    class="mb-2 flex-wrap"
                />
                <div class="mb-4 text-sm" itemprop="datePublished">
                    {{ date }}
                </div>
                <div class="mb-2 text-xl font-bold" itemprop="headline">
                    {{ card.title }}
                </div>
                <WysiwygContent
                    :content="description"
                    :class="wysiwigContentClasses"
                />
            </div>
            <div class="mt-8">
                <NuxtLink :to="card.slug">Learn More</NuxtLink>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import ArticleCardType from '~/types/ArticleCardType';
import { formattedDate } from '~/utils/helpers';
import { computed } from 'vue';

export interface Props {
    card: ArticleCardType;
    size?: string;
}
const props = withDefaults(defineProps<Props>(), {
    size: 'small',
});

const isSmall = computed<boolean>(() => {
    return props.size === 'small';
});
const isMedium = computed<boolean>(() => {
    return props.size === 'medium';
});
const isLarge = computed<boolean>(() => {
    return props.size === 'large';
});

const tags = computed<string[]>(() => {
    return props.card.categories.map((category: string) => {
        return category.replace(/-/g, ' ');
    });
});

const mainClasses = computed<string>(() => {
    if (isSmall.value) {
        return 'flex-col';
    }
    return '';
});
const imageContainerClasses = computed<string>(() => {
    if (isSmall.value) {
        return 'rounded-t-[10px] h-[235px]';
    }
    return 'rounded-bl-[10px] rounded-tl-[10px] w-1/3';
});
const imageClasses = computed<string>(() => {
    if (isSmall.value) {
        return 'rounded-t-[10px]';
    }
    return 'rounded-bl-[10px] rounded-tl-[10px]';
});
const contentClasses = computed<string>(() => {
    if (isSmall.value) {
        return 'p-4';
    }
    return 'px-4 py-12';
});

const description = computed<string>(() => {
    let content = props.card.shortDescription;
    if (isLarge.value) {
        content = props.card.description;
    }
    if (typeof content === 'string') {
        return content;
    }
    return renderRichText(content);
});

const wysiwigContentClasses = computed<string>(() => {
    if (isLarge.value) {
        return 'line-clamp-5';
    }
    return 'line-clamp-3';
});

const truncatedContent = (content) => {
    const length = 180;
    const ending = '...';
    return content.substring(0, length - ending.length) + ending;
};

const date = computed<string>(() => {
    return formattedDate(props.card.date);
});
</script>
